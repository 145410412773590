import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Row } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import Switch from '@mui/material/Switch';

function Ledger() {
    const [datefrom,setDateFrom]=useState('');
    const [dateto,setDateTo]=useState('');
    const [isClick,setIsClick]=useState(false);
    const [salesman,setSalesman]=useState([]);
    const [defaultsalesman,setDefaultSalesman]=useState(0);

    const [sector,setSector]=useState([]);
    const [defaultsector,setDefaultSector]=useState(0);
    const [subsector,setSubSector]=useState([]);
    const [defaultsubsector,setDefaultSubSector]=useState(0);
    const [customer,setCustomer]=useState([]);
    const [defaultcustomer,setDefaultCustomer]=useState(0);
    const [distributer,setDistributer]=useState([]);
    const [defaultdistributer,setDefaultDistributer]=useState(0);
    const label = { inputProps: { 'aria-label': 'Size switch demo' } };
const [check,setChecked]=useState(true);


    useEffect(()=>{
        axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get(`/api/getsalesman`).then(res=>{
        setDefaultSalesman(res.data.salesman[0]['id']);
        setSalesman(res.data.salesman);
      
      });
      
      axios.get(`/api/getdistributers`).then(res=>{
        setDefaultDistributer(res.data.distributers[0]['id']);
        setDistributer(res.data.distributers);
      
      });
      

      axios.get(`/api/getsectors`).then(res=>{
        setSector(res.data.sectors);
        setDefaultSector(res.data.sectors[0]['id']);
      
      });
     
      
        }); },[]);
       
        useEffect(() => {
            if (defaultsector) {
              
              axios.get(`/api/getsubsectors/${defaultsector}`)
              .then(response => {
                setSubSector(response.data.subsectors);
                setDefaultSubSector(response.data.subsectors[0]['id']);
              })
              .catch(error => {
                console.error(error);
              });
            }
           
          },[defaultsector]);

          useEffect(()=>{
  
            if(defaultsubsector){
            
            
              axios.get(`/api/getshop/${defaultsubsector}`)
              .then(response => {
                
            
             
            
                const sortedShpos = [...response.data.shop].sort((a, b) => a.name.localeCompare(b.name));
                setCustomer(sortedShpos);
              
               setDefaultCustomer(response.data.shop[0]['user_id']);
             
           
              })
              .catch(error => {
                console.error(error);
              });
            }
            },[defaultsubsector])


    const handleSubmit=(e)=>{
        e.preventDefault();
        setIsClick(true);
        const data={
        'customer':check?defaultcustomer:defaultdistributer,
        'fromdate':datefrom,
        'todate':dateto,
        'salesmanid':defaultsalesman
        
        }
        axios({
          url: 'api/ledger',
          method: 'POST',
          data:data,
          responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            window.open(url, '_blank');
        setIsClick(false);
        });
        
        }
  return (
    <div>
       <div className='row fadeInDown animated'>
       <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Ledger Report </h2> <hr/>
            </div>   
       <Row className='g-2'>
    <div className='col-md mt-4'>
  
    <FloatingLabel  label="Date From" >
        <Form.Control type="date"  name="datefrom" placeholder='Date From'  value={datefrom} onChange={(e)=>setDateFrom(e.target.value)} />
      </FloatingLabel>
    </div>
    <div className='col-md mt-4'>
  
    <FloatingLabel  label="Date To" >
        <Form.Control type="date"  name="dateto" placeholder='Date To'  value={dateto} onChange={(e)=>setDateTo(e.target.value)} />
      </FloatingLabel>
    </div></Row>
<Row className='g-2'>
<div className='col-md mt-4'>

    <FloatingLabel  label="Region Name">

      <Form.Select aria-label="Sector Name" value={defaultsector} onChange={(e)=>setDefaultSector(e.target.value)}  name='sector' >
       
      {sector && sector.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>


<div className='col-md mt-4'>
    <FloatingLabel  label="City Name">
   
      <Form.Select aria-label="Sector Name" value={defaultsubsector}  onChange={(e)=>setDefaultSubSector(e.target.value)}  name='sector' >
      <option>Select Sub Sector</option>
      {subsector && subsector.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>

</Row>
<Row className='g-2'>

{check?
<div className='col-md mt-4'>
    
    <FloatingLabel  label="Customer Name">
   
      <Form.Select aria-label="Customer Name" value={defaultcustomer} onChange={(e)=>setDefaultCustomer(e.target.value)}  name='customer' >
       <option>Select Customer</option>
      {customer && customer.map(item => (
        
  <option key={item.user_id} value={item.user_id}>
    {item.name} 
    
     </option>
    
    
    ))}
      </Form.Select>
    </FloatingLabel>
    </div>
  :  
  <div className='col-md mt-4 animated faleInDown'>
    
    <FloatingLabel  label="Supplier Name">
   
      <Form.Select aria-label="Distributer Name" value={defaultdistributer} onChange={(e)=>setDefaultDistributer(e.target.value)}  name='customer' >
       <option value={0}>Select Distributer</option>
      {distributer && distributer.map(item => (
        
  <option key={item.d} value={item.id}>
    {item.name} 
    
     </option>
    
    
    ))}
      </Form.Select>
    </FloatingLabel>
    </div>
  }


<div className='col-md mt-4'>
    <FloatingLabel  label="Sales Associate">
   
      <Form.Select aria-label="Salesman Name" value={defaultsalesman} onChange={(e)=>setDefaultSalesman(e.target.value)}  name='salesman' >
      <option value={0}>Select All</option>  
      {salesman && salesman.map(item => (
      
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>

</Row>
    <div className='col-md-10 mt-4'>

    <Row className='g-2 mt-4'>


<div className='col-md-11'>
<button  onClick={(e)=>handleSubmit(e)} className='btn btn-info btn-lg btn-block'>{isClick?  <Puff width={25} height={25} stroke="red"/>:'Ledger Report'}  </button>

</div>
<div className='col-md-1'>
<Switch {...label} checked={check} onChange={(e)=>setChecked(e.target.checked)} />

</div>
    </Row></div>
    </div></center>
    </div>


    </div>
  )
}

export default Ledger
